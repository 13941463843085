import React from 'react';
import classNames from 'classnames';
import { SectionTilesProps } from '../../utils/SectionProps';
import Button from '../elements/Button';

const propTypes = {
  ...SectionTilesProps.types
}

const defaultProps = {
  ...SectionTilesProps.defaults
}

class HeroFull extends React.Component {

  render() {
    const {
      className,
      topOuterDivider,
      bottomOuterDivider,      
      topDivider,
      bottomDivider,
      hasBgColor,
      invertColor,
      pushLeft,
      ...props
    } = this.props;

    const outerClasses = classNames(
      'hero section',
      topOuterDivider && 'has-top-divider',
      bottomOuterDivider && 'has-bottom-divider',
      hasBgColor && 'has-bg-color',
      invertColor && 'invert-color',
      className
    );

    const innerClasses = classNames(
      'hero-inner section-inner',
      topDivider && 'has-top-divider',
      bottomDivider && 'has-bottom-divider'
    );

    const tilesClasses = classNames(
      'tiles-wrap illustration-section-03 reveal-from-top',
      pushLeft && 'push-left'
    );

    const sectionHeader = {
      title: 'Un prix simple comme une livraison',
      paragraph: 'Ce montant ne correspond pas au montant total de la livraison mais uniquement des frais qui permettent le maintien et l\'amélioration de la solution'
    };


    return (
      <section
        {...props}
        className={outerClasses}
      >
        <div className="container">
          <div className={innerClasses}>
            <div className="hero-content invert-color center-content">
              <div className="container-xs">
                <h1 className="m-0 reveal-from-top">
                  {sectionHeader.title}
                </h1>
                <p>{sectionHeader.paragraph}</p>
              </div>
            </div>
            <div className="hero-figure pricing">
              <div className={tilesClasses} data-reveal-delay="100">

                <div className="tiles-item reveal-from-top" data-reveal-delay="200">
                  <div className="tiles-item-inner has-shadow">
                    <div className="pricing-item-content">
                      <div className="pricing-item-header center-content mb-24">
                        <div className="pricing-item-title text-xxs fw-500 pb-12">
                          PRIX UNIQUE
                        </div>
                        <div className="pricing-item-price pt-24 pb-24">
                          <span className="pricing-item-price-amount h1">
                            1,25
                            <span className="pricing-item-price-currency text-color-high">
                              €
                            </span>
                          </span>/par livraison
                          <span className="pricing-item-info">Ce prix ne comprend pas le montant payé pour le livreur</span>
                        </div>
                      </div>
                      <div className="pricing-item-features mb-40">
                        <div className="pricing-item-features-title fw-500 text-xs text-color-high mb-24">
                          Ce qui est inclus
                        </div>
                        <ul className="pricing-item-features-list list-reset text-xs">
                          <li className="is-checked">Accès à une plate-forme</li>
                          <li className="is-checked">Possibilité d'utiliser l'API</li>
                          <li className="is-checked">Service client</li>
                          <li className="is-checked">Webhooks</li>
                          {/* <li>Excepteur sint occaecat velit</li> */}
                        </ul>
                      </div>
                    </div>
                    <div className="pricing-item-cta mb-16">
                      <Button tag="a" color="primary" wide href="https://app.gorider.co/">Commencer vos livraisons</Button>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

HeroFull.propTypes = propTypes;
HeroFull.defaultProps = defaultProps;

export default HeroFull;