import React from 'react';
import classNames from 'classnames';
import { SectionSplitProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Image from '../elements/Image';
import TagTitle from '../elements/TagTitle';

const propTypes = {
  ...SectionSplitProps.types
}

const defaultProps = {
  ...SectionSplitProps.defaults
}

class FeaturesSplit extends React.Component {

  render() {

    const {
      className,
      topOuterDivider,
      bottomOuterDivider,      
      topDivider,
      bottomDivider,
      hasBgColor,
      invertColor,
      invertMobile,
      invertDesktop,
      alignTop,
      imageFill,
      ...props
    } = this.props;

    const outerClasses = classNames(
      'features-split section',
      topOuterDivider && 'has-top-divider',
      bottomOuterDivider && 'has-bottom-divider',
      hasBgColor && 'has-bg-color',
      invertColor && 'invert-color',
      className
    );

    const innerClasses = classNames(
      'features-split-inner section-inner',
      topDivider && 'has-top-divider',
      bottomDivider && 'has-bottom-divider'
    );

    const splitClasses = classNames(
      'split-wrap',
      invertMobile && 'invert-mobile',
      invertDesktop && 'invert-desktop',
      alignTop && 'align-top'
    );

    const sectionHeader = {
      title: '',
      paragraph: ''
    };

    return (
      <section
        {...props}
        className={outerClasses}
      >
        <div className="container">
          <div className={innerClasses}>
            <TagTitle>LA SOLUTION</TagTitle>
            <SectionHeader data={sectionHeader} className="center-content" />
            <div className={splitClasses}>

              <div className="split-item">
                <div className="split-item-content center-content-mobile">
                  <h3 className="mt-0 mb-16 reveal-from-left" data-reveal-delay="200">
                    Commandez votre livraison
                  </h3>
                  <p className="m-0 reveal-from-left" data-reveal-delay="400">
                    Selectionnez l’addresse de départ et l’addresse d’arrivée, payez et c’est parti. Le livreur arrivera chez vous pour récupérer la commande
                  </p>
                </div>
                <div className={
                  classNames(
                    'split-item-image center-content-mobile illustration-element-02 reveal-from-top',
                  )}>
                  <Image
                    src={require('./../../assets/images/features-split-top-01.png')}
                    alt="Features split 01"
                    width={'150px'}
                    />
                </div>
              </div>

              <div className="split-item">
                <div className="split-item-content center-content-mobile">
                  <h3 className="mt-0 mb-16 reveal-from-right" data-reveal-delay="200">
                    Livraison de qualité
                  </h3>
                  <p className="m-0 reveal-from-right" data-reveal-delay="400">
                    Chaque livreur prend un soin particulier pour vos produits, nous faisons tout pour satisfaire vos clients et une expérience de livraison de qualité.
                  </p>
                </div>
                <div className={
                  classNames(
                    'split-item-image center-content-mobile illustration-element-03 reveal-from-top'
                  )}>
                  <Image
                    src={require('./../../assets/images/features-split-top-02.png')}
                    alt="Features split 02"
                  />
                </div>
              </div>

              <div className="split-item">
                <div className="split-item-content center-content-mobile">
                  <h3 className="mt-0 mb-16 reveal-from-left" data-reveal-delay="200">
                    Disponible tous les jours
                  </h3>
                  <p className="m-0 reveal-from-left" data-reveal-delay="400">
                    Vous avez la possibilité de commander une livraison à n’importe quel moment, n’importe quel jour si nos livreurs sont connectés à leur application.
                  </p>
                </div>
                <div className={
                  classNames(
                    'split-item-image center-content-mobile illustration-element-04 reveal-from-top'
                  )}>
                  <Image
                    src={require('./../../assets/images/features-split-top-03.png')}
                    alt="Features split 03"
                  />
                </div>
              </div>

              <div className="split-item">
                <div className="split-item-content center-content-mobile">
                  <h3 className="mt-0 mb-16 reveal-from-right" data-reveal-delay="200">
                    Intégrer notre API
                  </h3>
                  <p className="m-0 reveal-from-right" data-reveal-delay="400">
                    La force de notre solution est la possibilité d’intégrer notre API sur toutes vos plate-formes de click & collect ou vos sites web déjà en ligne pour ainsi élargir votre clientèle et accélérer votre développement.
                  </p>
                </div>
                <div className={
                  classNames(
                    'split-item-image center-content-mobile illustration-element-03 reveal-from-top'
                  )}>
                  <Image
                    src={require('./../../assets/images/features-split-top-04.png')}
                    alt="Features split 02"
                  />
                </div>
              </div>

            </div>
          </div>
        </div>
      </section>
    );
  }
}

FeaturesSplit.propTypes = propTypes;
FeaturesSplit.defaultProps = defaultProps;

export default FeaturesSplit;