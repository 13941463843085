import React from 'react';

class PriceCalculator extends React.Component {
    state = {
      priceInputValue: "1000",
    };
  
    slider = React.createRef();
    sliderValue = React.createRef();
 
    handlePricingSlide = e => {
      this.setState({ priceInputValue: e.target.value });
      // this.handleSliderValuePosition(e.target);
    };
    
    getPricingData = (obj, pos) => {
      return 1000
    };
  
    render() {
        const goriderFees = 1.25
        const pickupCharge = 2
        const deliveryCharge = 2
        const distanceCharge = this.state.priceInputValue / 1000 < 1 ? 1 : this.state.priceInputValue / 1000
      return (
        <div className="pricing-calculator ">
          <div className="container-xs">
            <div className="pricing-slider center-content">
              <label className="form-slider">
                <h4>Quel est la distance de votre livraison (en mètres)?</h4>
                <input
                  type="range"
                  ref={this.slider}
                  min={500}
                  max={12500}
                  defaultValue={this.state.priceInputValue}
                  onChange={this.handlePricingSlide}
                />
              </label>
              <div ref={this.sliderValue} className="pricing-slider-value">
                {this.state.priceInputValue} mètres
              </div>
            </div>
            <div className="final-price">
              <p className="info-price">Récupération de la commande... <span className="price-amount">{pickupCharge}€</span></p>
              <p className="info-price">Livraison de la commande... <span className="price-amount">{deliveryCharge}€</span></p>
              <p className="info-price">Frais kilométriques... <span className="price-amount">{Math.round(distanceCharge * 100) / 100}€</span></p>
              <p className="info-price">Frais GoRider... <span className="price-amount">{goriderFees}€</span></p>
              <h2 className="info-total">Montant: {Math.round((pickupCharge + deliveryCharge + distanceCharge + goriderFees) * 100) / 100} € HT</h2>
            </div>
          </div>
          <div className="pricing-sub mb-5">
            <h4>Un prix raisonné et raisonnable pour tous les acteurs !</h4>
          </div>
        </div>
      );
    }
  }
  

export default PriceCalculator;