import React from 'react';
import classNames from 'classnames';

const TagTitle = ({
  className,
  ...props
}) => {

  const classes = classNames(
    'tagtitle',
    className,
    { 'is-light': props.isLight }
  );

  return (
    <div
      {...props}
      className={classes}
    >
        <span>{props.children}</span>
    </div>
  );
}

export default TagTitle;