import React from 'react';
// import section header
import SectionHeader from '../components/sections/partials/SectionHeader';
// import sections
import HeroPricing from '../components/sections/HeroPricing';
import Clients from '../components/sections/Clients';
import GenericSection from '../components/sections/GenericSection';
import Cta from '../components/sections/Cta';
// import some required elements
import Accordion from '../components/elements/Accordion';
import AccordionItem from '../components/elements/AccordionItem';
import PriceCalculator from '../components/elements/PriceCalulator';

class Pricing extends React.Component {
  goToApp() {
    console.log('goToApp')
  }

  render() {
    const genericSectionFAQ = {
      title: 'FAQ - Foire aux questions'
    }

    return (
      <React.Fragment>
        <HeroPricing hasBgColor className="illustration-section-01" />
        <PriceCalculator />
        <Clients topDivider bottomDivider />

        <GenericSection topDivider>
          <div className="container-xs">
            <SectionHeader data={genericSectionFAQ} className="center-content" />
            <Accordion>
              <AccordionItem title="Nisi porta lorem mollis aliquam ut." active>
                Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups.
              </AccordionItem>
              <AccordionItem title="Nisi porta lorem mollis aliquam ut.">
                Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups.
              </AccordionItem>
              <AccordionItem title="Nisi porta lorem mollis aliquam ut.">
                Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups.
              </AccordionItem>
              <AccordionItem title="Nisi porta lorem mollis aliquam ut.">
                Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups.
              </AccordionItem>
              <AccordionItem title="Nisi porta lorem mollis aliquam ut.">
                Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups.
              </AccordionItem>
            </Accordion>
          </div>
        </GenericSection>
        <Cta buttonAction={this.goToApp} data={{title: "Lancez une livraison", description: "Choisissez une adresse et envoyer votre livraison"}} buttonText="Créer votre compte" invertColor split />
      </React.Fragment>
    );
  }
}

export default Pricing;