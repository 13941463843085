import React from 'react';
import classNames from 'classnames';
import { SectionProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Timeline from '../elements/Timeline';
import TimelineItem from '../elements/TimelineItem';

const propTypes = {
  ...SectionProps.types
}

const defaultProps = {
  ...SectionProps.defaults
}

class Roadmap extends React.Component {

  render() {

    const {
      className,
      topOuterDivider,
      bottomOuterDivider,      
      topDivider,
      bottomDivider,
      hasBgColor,
      invertColor,
      ...props
    } = this.props;

    const outerClasses = classNames(
      'roadmap section',
      topOuterDivider && 'has-top-divider',
      bottomOuterDivider && 'has-bottom-divider',
      hasBgColor && 'has-bg-color',
      invertColor && 'invert-color',
      className
    );

    const innerClasses = classNames(
      'roadmap-inner section-inner',
      topDivider && 'has-top-divider',
      bottomDivider && 'has-bottom-divider'
    );

    const sectionHeader = {
      title: 'Roadmap de GoRider',
      paragraph: 'Un produit est une dynamique d\'équipe et des objectifs clairs.'
    };

    return (
      <section
        {...props}
        className={outerClasses}
      >
        <div className="container">
          <div className={innerClasses}>
            <SectionHeader data={sectionHeader} className="center-content" />
            <Timeline>
              <TimelineItem title="Juillet 2022">
                Déploiement de la solution et test directement sur le restaurant Le Factory's (30 commandes par jour)
              </TimelineItem>
              <TimelineItem title="Septembre 2022">
                Déploiement sur nos autres partenaires la solution et mise à disposition de l'API
              </TimelineItem>
              <TimelineItem title="Decembre 2022">
                Lancement du plan d'acquisition commerciale
              </TimelineItem>
              <TimelineItem title="Février 2023">
                Création de solutions (FoodRider) en surcouches à GoRider pour les commerces
              </TimelineItem>
              <TimelineItem title="Mai 2023">
                Déploiement dans nos restaurants partenaire de FoodRider
              </TimelineItem>
            </Timeline>
          </div>
        </div>
      </section>
    );
  }
}

Roadmap.propTypes = propTypes;
Roadmap.defaultProps = defaultProps;

export default Roadmap;