import React from 'react';
import HeroFull from '../components/sections/HeroFull';
import FeaturesTiles from '../components/sections/FeaturesTiles';
import FeaturesSplit from '../components/sections/FeaturesSplit';
import Cta from '../components/sections/Cta';
import TagList from '../components/sections/TagList';

class Home extends React.Component {
  goToRiderApp() {
    window.location.href = 'https://rider.gorider.co'
  }

  goToApp() {
    window.location.href = 'https://app.gorider.co'
  }
  
  render() {
    return (
      <React.Fragment>
        <HeroFull hasBgColor invertColor className="illustration-section-01" />
        <FeaturesTiles />
        <FeaturesSplit topDivider invertMobile imageFill />
        <Cta buttonAction={this.goToRiderApp} data={{title: "Je souhaite devenir coursier GoRider", description: "Commencez dés maintenant à utiliser notre solution. Une solution qui met le livreur en premier plan et qui estime son travail. Quand la course devient un jeu !"}} buttonText="Devenir coursier" fullWidth invertColor split />
        <TagList />
        <Cta buttonAction={this.goToApp} data={{title: "Lancez une livraison", description: "Choisissez une adresse et envoyer votre livraison"}} buttonText="Créer votre compte" invertColor split />
      </React.Fragment>
    );
  }
}

export default Home;